<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Course Title -->
    <PageHeader class="pb-4" heading="Drop Rates" :sub-heading="store.app.onMobile ? 'Last Refreshed: ' + store.data.lastRefresh : ''">
      <v-dialog v-model="showDialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="accent" v-bind="attrs" v-on="on">mdi-information</v-icon>
        </template>
        <v-card :outlined="store.app.darkMode" style="border-radius: 5px">
          <v-card-title class="pt-6 pb-4 text-h5 font-weight-bold accent--text">Drop Rate Treemap Info</v-card-title>
          <v-card-text class="pb-0">
            <p class="text--secondary">The below tree map is an interactive visualization of the drop rates across
              a range of criteria related to UofT courses and professors. If users intend to find a specific courses
              drop rate it is advised to use the course directory instead.</p>
            <p class="text--secondary">Drop rates are calculated using timetable enrolment numbers. The starting value is taken
              at the waitlist deadline with subsequent updates until the last day of class being used
              to calculate the rates. <strong>Data should only be used as a general guide!</strong></p>
            <p class="pt-3 text--secondary">Example map navigation structure:</p>
            <p class="font-weight-medium text--secondary">[1] Campus / [2] Field of Study / [3] Course / [4] Professor</p>
            <ul>
              <li class="text--secondary"><strong>[1]</strong> Average drop rate for each campus</li>
              <li class="text--secondary"><strong>[2]</strong> Average drop rate for each subject/section</li>
              <li class="text--secondary"><strong>[3]</strong> Average drop rate for each course</li>
              <li class="text--secondary"><strong>[4]</strong> Average drop rate for each professor who has taught the course and the rate for when they taught it</li>
            </ul>
          </v-card-text>
          <v-card-actions class="pt-0 pb-4">
            <v-spacer/>
            <v-btn color="accent" text @click="showDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </PageHeader>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row justify="center" class="mt-0">
      <v-col class="contentMaxWidth" :style="!store.app.onMobile ? 'padding-top: 0' : ''">
        <span v-if="!store.app.onMobile">
          <!-- HEADER - Section header and quick link options -->
          <v-row align="center">
            <p class="font-weight-regular text-h6 text-md-h5 text-lg-h5 mb-0 mr-6">Interactive Map</p>
            <v-spacer/>
            <p class="text-body-1 font-weight-medium mb-1 mr-3">View Mode:</p>
            <v-chip-group v-model="viewMode.active" active-class="accent--text font-weight-medium" mandatory>
              <v-chip v-for="(mode, idx) in viewMode.modes" :key="idx" filter label color="transparent"
                      class="text-body-1 mr-0 ml-1">{{  mode.label  }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <!-- MAP NAVIGATION SECTION - Navigation breadcrumbs to go backwards on the map -->
          <v-row align="center" class="pt-3">
            <p class="text-body-1 font-weight-medium mb-0 mr-2">Map Navigation:</p>
            <p v-for="(entry, idx) in chartData.active" :key="idx" class="mb-0">
              <span v-if="idx !== chartData.active.length - 1" @click="chartNavigate(entry)"
                    class="text-body-1 font-weight-bold accent--text hover mb-0" >{{ entry }}
              </span>
              <span v-else class="text-body-1 font-weight-medium mb-0">{{ entry }}</span>
              <span v-if="idx !== chartData.active.length - 1" class="mx-1 font-weight-bold"> / </span>
            </p>
          </v-row>
          <!-- TREEMAP SECTION - Treemap and map navigation elements -->
          <v-row class="dropMap" :style="dropMapWidth" justify="center">
            <apexchart type="treemap" :options="chartOptions" :series="chartData.series" style="width: 100%"/>
          </v-row>
          <v-row v-if="chartData.filters.level === 3 && courseDetails" class="px-1 pt-2 pb-5">
            <p class="text-h6 font-weight-medium mb-3">{{ courseDetails.course }}</p>
            <InfoDisplay :course-info="courseDetails" dense :show-tree="false" show-semesters view-more dynamic-codes class="pa-3"/>
          </v-row>
          <v-row class="py-4"><v-divider/></v-row>
        </span>
        <!-- TABLE SECTION - Table header and search area -->
        <v-row v-if="store.app.onMobile" align="center" class="mt-0">
          <v-col class="pa-0">
            <v-text-field v-model="search" label="Course or Keyword" prepend-inner-icon="mdi-magnify" single-line
                          hide-details solo-inverted background-color="border" flat clearable/>
            <v-row justify="end" class="mx-0 my-3">
              <DropMenu icon="mdi-align-vertical-bottom" :options="viewMode.modes"
                        @optionUpdate="viewMode.active = $event"/>
            </v-row>
            <v-row align="center" class="mt-4 mb-2 mx-0">
              <p class="font-weight-medium mb-0 mr-2">Navigation:</p>
              <p v-for="(entry, idx) in chartData.active" :key="idx" class="mb-0">
              <span v-if="idx !== chartData.active.length - 1" @click="chartNavigate(entry)"
                    class="text-body-1 font-weight-bold accent--text hover mb-0" >{{ entry }}
              </span>
                <span v-else class="text-body-1 font-weight-medium mb-0">{{ entry }}</span>
                <span v-if="idx !== chartData.active.length - 1" class="mx-1 font-weight-bold"> / </span>
              </p>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else class="py-3" align="center">
          <p class="font-weight-regular text-h6 text-md-h5 text-lg-h5 mb-0 pr-6">Drop Rate Table</p>
          <v-text-field v-model="search" label="Course or Keyword" prepend-inner-icon="mdi-magnify" single-line
                        hide-details solo-inverted background-color="border" flat clearable/>
        </v-row>
        <!-- DATA TABLE - Data table for the drop rates that show on the map -->
        <v-row class="courseTableRow" justify="center">
          <v-col class="px-0 pt-0">
            <v-data-table :headers="tableHeaders" :items="tableData" :search="search" @click:row="rowClick"
                          :disable-sort="store.app.onMobile" class="row-pointer" mobile-breakpoint="0"
                          :footer-props="tableFooters">
              <template v-slot:item.label="{ item }">
                <h4 v-if="![0, 3, 5].includes(chartData.filters.level)" class="font-weight-regular my-4">
                  {{ item.label }}
                </h4>
                <h4 v-else class="font-weight-regular">-</h4>
              </template>
              <template v-slot:item.drop="{ item }">
                <chip :text="item.drop" class-name="justify-center" style="width: 60px" label drop-rate/>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import Chip from '@/components/Chip.vue'
import DropMenu from '@/components/DropMenu'
import InfoDisplay from '@/components/InfoDisplay'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'DropRate',
  components: { PageHeader, Chip, DropMenu, InfoDisplay },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    loading: true,
    showDialog: false,
    courseDetails: null,
    viewMode: {
      active: 0,
      modes: [{ label: 'Exploration' }, { label: 'Top Professors' }, { label: 'Top Courses' }]
    },
    search: '',
    quickLinks: ['Campus', 'Field of Study', 'Professors', 'Courses'],
    chartData: {
      series: [],
      labels: [],
      meta: {},
      filters: { level: 0, campus: null, field: null, course: null, instructor: null },
      active: ['Campus']
    },
    mobileHeaders: [0, 1, 3],
    headers: [
      { text: 'Item', value: 'item' },
      { text: 'Label', value: 'label' },
      { text: 'Category', value: 'category' },
      { text: 'Drop Rate', value: 'drop' }
    ]
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.store.errors.graphQL) {
        vm.getDropData()
      } else {
        vm.$toast.error('An error occurred when contacting the server. Please try again later.')
        vm.store.errors.graphQL = null
      }
    })
  },
  watch: {
    viewMode: {
      deep: true,
      handler (newValue) {
        this.chartData.filters.level = !newValue.active ? 0 : newValue.active + 4
        this.chartData.active = [!newValue.active ? 'Campus' : newValue.modes[newValue.active].label]
        this.getDropData()
        // GTAG
        this.$gtag.event('drop_mode_' + newValue.modes[newValue.active].label, { value: 1 })
      }
    }
  },
  computed: {
    dropMapWidth () {
      return { '--map-width': [3, 4].includes(this.chartData.filters.level) ? '104%' : '102%' }
    },
    tableData: function () {
      const dropData = []
      if (this.chartData.series.length) {
        this.chartData.series[0].data.forEach((item, idx) => {
          dropData.push({
            item: (item.x.length < 1) ? 'Unspecified Professor' : item.x,
            label: this.chartData.labels[idx],
            category: this.chartData.active[this.chartData.active.length - 1],
            drop: (item.y < 0) ? 0 : item.y
          })
        })
      }
      return dropData
    },
    tableHeaders () {
      if (!this.store.app.onMobile) {
        return this.headers
      } else {
        return this.headers.filter((element, index) => this.mobileHeaders.includes(index))
      }
    },
    tableFooters () {
      return {
        showFirstLastPage: !this.store.app.onMobile,
        itemsPerPageText: !this.store.app.onMobile ? 'Courses per page' : ''
      }
    },
    chartOptions: function () {
      return {
        chart: {
          toolbar: { show: false },
          redrawOnParentResize: true,
          background: this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF',
          events: { dataPointSelection: (event, chartContext, options) => { this.updateChart(options, false) } },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: { enabled: true, delay: 150 },
            dynamicAnimation: { enabled: true, speed: 350 }
          }
        },
        stroke: { colors: [this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF'] },
        theme: { mode: this.store.app.darkMode ? 'dark' : 'light' },
        plotOptions: {
          treemap: {
            enableShades: false,
            shadeIntensity: 0.25,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                { from: -10, to: 10, color: '#04DB8E' },
                { from: 10.1, to: 20, color: '#2B9EFC' },
                { from: 20.1, to: 30, color: '#FAAC52' },
                { from: 30.1, to: 100, color: '#DC143C' }
              ]
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (text, op) => {
            return [(this.chartData.series[0].name % 2 !== 0) ? text : text.slice(0, 6), op.value >= 0 ? op.value + '%' : 0 + '%']
          },
          offsetY: -5
        },
        tooltip: {
          y: { formatter: (value, opt) => { return this.chartData.labels[opt.dataPointIndex] } }
        }
      }
    }
  },
  methods: {
    async getDropData () {
      this.loading = true
      const q = {
        query: 'query getDropData ($campus: String, $level: Float!, $field: String, $course: String, $instructor: String) { ' +
          'getDropData (campus: $campus, level: $level, field: $field, course: $course, instructor: $instructor) { series, labels, meta, lastRefresh } }',
        operationName: 'getDropData',
        variables: {}
      }
      Object.keys(this.chartData.filters).forEach(filter => {
        q.variables[filter] = (filter in this.chartData.meta) ? this.chartData.meta[filter][this.chartData.filters[filter]] : this.chartData.filters[filter]
      })
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.chartData.series = graphQlRes.data.getDropData.series
            this.chartData.meta = graphQlRes.data.getDropData.meta
            if ([0, 3, 5].includes(this.chartData.filters.level)) {
              this.chartData.labels = graphQlRes.data.getDropData.labels.map((label) => parseFloat(label).toFixed(1) + '%')
            } else {
              this.chartData.labels = graphQlRes.data.getDropData.labels
            }
            if (this.chartData.filters.level === 3) {
              this.courseDetails = { course: '' }
              this.getDetails(this.chartData.filters.course)
            } else {
              this.courseDetails = null
            }
            this.store.data.setLastRefresh(graphQlRes.data.getDropData.lastRefresh)
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.loading = false
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async getDetails (course) {
      // Call backend for course details
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { code, course, description prereq, semesters } }',
        variables: { course: course },
        operationName: 'courseInfo'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.courseDetails = graphQlRes.data.courseInfo.length ? graphQlRes.data.courseInfo[0] : null
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    updateChart (opt, index) {
      const dataIDX = opt ? opt.dataPointIndex : index
      // Level map to enable wraparound behaviour on the map
      const levelMap = { 0: 0, 1: 1, 2: 2, 3: 3, 4: 2, 5: 3, 6: 2 }
      const currLevel = levelMap[this.chartData.series[0].name]
      const map = ['campus', 'field', 'course', 'instructor']
      // Update filters before getting new data
      const filterValue = this.chartData.series[0].data[dataIDX].x
      // Set values
      this.chartData.filters[map[currLevel]] = filterValue
      // GTAG
      this.$gtag.event('drop_' + filterValue, { value: 1 })
      // Compute the splice offset for updating the map navigation breadcrumbs
      let offset = 0
      if (this.viewMode.active > 0) {
        if (this.viewMode.active === 1) {
          offset = (currLevel !== 2) ? (3 - this.viewMode.active) : 0
        } else {
          offset = (currLevel !== 2 || this.chartData.active.length < 2) ? (3 - this.viewMode.active) : 1
        }
      } else {
        offset = -1
      }
      this.chartData.active.splice(currLevel - offset, 2)
      this.chartData.active.push((currLevel + 1 === 2) ? this.chartData.labels[dataIDX] : filterValue)
      // Handle blank professor entries
      const activeLength = this.chartData.active.length - 1
      if (this.chartData.active[activeLength].length < 1) this.chartData.active[activeLength] = 'Unspecified Professor'
      this.chartData.filters.level = currLevel + 1
      // Fetch the new data
      this.getDropData()
    },
    chartNavigate (section) {
      let newLevel = -1
      const sectionIDX = this.chartData.active.indexOf(section)
      this.chartData.active = this.chartData.active.slice(0, sectionIDX + 1)
      if (!this.viewMode.active) {
        newLevel = sectionIDX
      } else if (this.viewMode.active === 1) {
        newLevel = 5 - sectionIDX
      } else {
        newLevel = (sectionIDX === 0) ? 6 : sectionIDX + 2
      }
      if (this.chartData.filters.level !== newLevel) {
        this.chartData.filters.level = newLevel
        this.getDropData()
      }
    },
    rowClick (item) {
      this.search = ''
      this.updateChart(false, this.chartData.series[0].data.findIndex((element) => { return element.x === item.item }))
    }
  }
}
</script>

<style scoped>
  li {
    padding-bottom: 8px;
  }
  >>>.apexcharts-svg:hover {
    cursor: pointer;
  }
  >>>.v-toolbar__content {
    padding-right: 0;
  }
  >>>.v-input__icon {
    margin-right: 6px;
  }
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
  .v-application ul, .v-application ol {
    padding-left: 14px;
  }
  .v-application a {
    font-weight: 600;
    text-decoration: none;
    color: #003C85 !important;
  }
  .dropMap {
    width: var(--map-width);
  }

</style>
